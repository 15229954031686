import React from 'react';
import ExampleSpinner from './example-spinner-flowbite';
import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import logout from '../utils/logout';
import useUser from '../hooks/use-user';

export default function ExampleViewComponent() {
    const [text, setText] = useState('nothing yet... ');
    const [catfact, setCatfact] = useState('');
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const navigate = useNavigate();

    async function getCatfact() {
        try {
            const response = await axios.get('/catfact', {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            if (response.status === 200) {
                setText('');
                setCatfact(response.data);
            }
        } catch (error) {
            if (error.response.status === 401) {
                return logout(auth, setAuth, setUserState);
            }
            console.error(error.message);
            return navigate('/login');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        //artificially delay catfact response to see spinner ;)
        setTimeout(() => {
            getCatfact();
        }, 900);
        //eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col gap-4 items-center min-h-screen">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Todays Catfact:
            </h5>
            <div className="p-1" id="testdiv">
                {text}
            </div>
            {catfact === '' && <ExampleSpinner />}
            <p>{catfact}</p>
        </div>
    );
}
