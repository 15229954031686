import { createContext, useEffect, useState } from 'react';
import useUser from '../../hooks/use-user';
import useAuth from '../../hooks/useAuth';
import axios from '../../utils/axios';
import logout from '../../utils/logout';

const OrderContext = createContext({});

export function OrderProvider({ children }) {
    const { auth, setAuth } = useAuth();
    const { setUserState } = useUser();
    const orderId = localStorage.getItem('orderId');
    const [activeOrder, setActiveOrder] = useState({
        orderId: orderId ?? 'undefined',
        orderLoaded: false,
    });
    async function getOrderInfo() {
        try {
            const response = await axios.get(`/order/${activeOrder.orderId}`, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            if (response.status === 200) {
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderLoaded: true, orderId: _id });
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
        }
    }
    //try getting order
    useEffect(() => {
        if (activeOrder.orderId !== 'undefined' && !activeOrder.orderLoaded && auth?.token) {
            getOrderInfo();
        }
    });
    return (
        <OrderContext.Provider value={{ activeOrder, setActiveOrder }}>
            {children}
        </OrderContext.Provider>
    );
}

export default OrderContext;
