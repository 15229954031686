import { createContext, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axios from '../../utils/axios';
import logout from '../../utils/logout';

const UserContext = createContext({});

export function UserProvider({ children }) {
    //get role from storage to allow site refresh without redirect to login 
    const role = sessionStorage.getItem('role')
    const [userState, setUserState] = useState({
        name: '',
        email: '',
        userRole: Number(role),
        assignedDocs: [],
    });
    const { auth, setAuth } = useAuth();
    async function getUserInfo() {
        try {
            const response = await axios.get('/user/', {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            if (response.status === 200) {
                setUserState({
                    email: response.data.email,
                    name: response.data.name,
                    userRole: response.data.userRole,
                    assignedDocs: response.data.assignedDocs,
                });
                sessionStorage.setItem('role', response?.data?.userRole)
            }
        } catch (error) {
            console.error(error);
            return logout(auth, setAuth, setUserState);
        }
    }
    //try getting current user
    useEffect(() => {
        if ((userState.name === '' || userState.email === '') && auth?.token) {
            getUserInfo();
        }
    });
    return (
        <UserContext.Provider value={{ userState, setUserState }}>{children}</UserContext.Provider>
    );
}

export default UserContext;
