import { Navigate, Outlet } from 'react-router-dom';
import useUser from '../hooks/use-user';

export const USER_ROLE = {
    DOC: 0,
    LAB: 1,
    ADMIN: 42,
};

export default function RequireRole({ role }) {
    const { userState } = useUser();
    switch (role) {
        case USER_ROLE.DOC:
            return userState.userRole === USER_ROLE.DOC ? <Outlet /> : <Navigate to="/login" />;
        case USER_ROLE.LAB:
            return userState.userRole === USER_ROLE.LAB ? <Outlet /> : <Navigate to="/login" />;
        default:
            <Navigate to="/login" />;
    }
}
