import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import LoginForm from './login-form';
import { useState } from 'react';

const FADE_DURATION = 500;

export default function LoginView() {
    const [fade, setFade] = useState(true);
    return (
        <div className="w-full lg:flex items-center mt-10">
            <div className="w-full lg:w-1/3">
                <div className="flex items-center justify-center">
                    <div
                        className={`flex transition-all duration-${FADE_DURATION} justify-around ${
                            fade ? 'opacity-0' : 'opacity-100'
                        }`}
                    >
                        <LockOpenIcon className="w-1/2 lg:w-full text-blue-500 pb-8 lg:pb-0" />
                    </div>
                    <div
                        className={`flex transition-all duration-${FADE_DURATION} justify-around ${
                            fade ? 'opacity-100' : 'opacity-0'
                        }`}
                    >
                        <LockClosedIcon className="w-1/2 lg:w-full text-blue-500 pb-8 lg:pb-0" />
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-2/3 lg:pl-24">
                <LoginForm fadeDuration={FADE_DURATION} toggleTransition={() => setFade()} />
            </div>
        </div>
    );
}
