import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useUser from '../hooks/use-user';
import { Button } from 'flowbite-react';
import useOrder from '../hooks/use-order';
import useErrMsg from '../hooks/use-errormsg';
import axios from '../utils/axios';
import logout from '../utils/logout';
import DefaultAlerts from './default-alerts';

export default function StarterView() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const { userState, setUserState } = useUser();
    const { setActiveOrder } = useOrder();
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const { errMsg, setErrMsg, successMsg, stdErrMsg } = useErrMsg();

    useEffect(() => {
        if (!auth?.token) {
            navigate('/login');
        }
    });

    async function createOrderForFiles() {
        setErrMsg(stdErrMsg);
        try {
            let response;
            response = await axios.post(
                '/order/createOrder',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );
            if (response.status === 200) {
                setDisplaySuccessMsg(true);
                setErrMsg(stdErrMsg);
                const { _id, ...body } = response.data;
                setActiveOrder({ ...body, orderId: _id });
                try {
                    localStorage.setItem('orderId', response.data._id);
                } catch (error) {
                    console.error(error.message);
                }
                return navigate('/fileUpload', { state: { filesOnly: true } });
            }
        } catch (error) {
            // on failure: print error and display error message
            if (!error?.response) {
                setErrMsg('Server konnte nicht erreicht werden.');
            }
            if (error?.response?.status === 401) {
                setErrMsg(stdErrMsg);
                return logout(auth, setAuth, setUserState);
            }
            console.error(error);
            setDisplayErrMsg(true);
        }
    }
    return (
        <div>
            <div className="flex flex-col gap-4 min-h-screen">
                <div className="flex justify-center">
                    <h1>Willkommen</h1>
                    <h1 className="ml-2 text-blue-500 underline">{`${userState.name}`}</h1>
                    <h1>!</h1>
                </div>
                <div className="flex justify-center pt-20">
                    <Button
                        outline={true}
                        className="lg:w-1/2 md:w-1/2 sm:w-3/4"
                        gradientDuoTone="cyanToBlue"
                        onClick={() => {
                            try {
                                localStorage.removeItem('orderId');
                            } catch (error) {
                                console.error(error);
                            }
                            setActiveOrder({ orderId: 'undefined', orderLoaded: false });
                            navigate('/neworder');
                        }}
                    >
                        <h1>neuen Auftrag anlegen!</h1>
                    </Button>
                </div>
                <div className="flex justify-center pt-10">
                    <Button
                        outline={true}
                        className="lg:w-1/2 md:w-1/2 sm:w-3/4"
                        gradientDuoTone="tealToLime"
                        onClick={async () => {
                            try {
                                localStorage.removeItem('orderId');
                            } catch (error) {
                                console.error(error);
                            }
                            await createOrderForFiles();
                        }}
                    >
                        <h1>nur Dokumente / Aufnahmen hochladen!</h1>
                    </Button>
                </div>
                <div className="flex justify-center pt-10">
                    <Button
                        outline={true}
                        className="lg:w-1/2 md:w-1/2 sm:w-3/4"
                        gradientDuoTone="greenToBlue"
                        onClick={() => {
                            navigate('/orderview');
                        }}
                    >
                        <h1>zur Auftragsübersicht!</h1>
                    </Button>
                </div>
                <div>
                    <DefaultAlerts
                        displaySuccessMsg={displaySuccessMsg}
                        displayErrMsg={displayErrMsg}
                        errMsg={errMsg}
                        successMsg={successMsg}
                    />
                </div>
            </div>
        </div>
    );
}
