import { PowerIcon } from '@heroicons/react/24/solid';
import { Navbar, Dropdown } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import logout from '../utils/logout';
import useUser from '../hooks/use-user';
import { USER_ROLE } from './require-role';
import logo from '../logo512.png';

export default function TopNavbar() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { userState, setUserState } = useUser();

    if (auth?.token) {
        return (
            <nav className="rounded-lg bg-white shadow p-6 mb-8">
                <Navbar fluid>
                    <Navbar.Brand className="cursor-pointer" onClick={() => navigate('/home')}>
                        <img
                            src={logo}
                            className="mr-3 h-9 sm:h-9 text-blue-500"
                            alt="Logo"
                            onClick={() => navigate('/home')}
                        />
                        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                            Digitales Auftragsformular
                        </span>
                    </Navbar.Brand>
                    <div className="flex order-2">
                        {userState.userRole === USER_ROLE.DOC && <Navbar.Toggle />}
                        <Dropdown inline label={<PowerIcon className="h-9" />}>
                            <Dropdown.Header>
                                <span className="block text-sm">{userState.name}</span>
                                <span className="block truncate text-sm font-medium">
                                    {userState.email}
                                </span>
                            </Dropdown.Header>
                            {userState.userRole === USER_ROLE.DOC && (
                                <Dropdown.Item onClick={() => navigate('/orderview')}>
                                    Alle Aufträge
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => navigate('/editUser')}>
                                Benutzer-Einstellungen
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => logout(auth, setAuth, setUserState)}>
                                Ausloggen
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    {userState.userRole === USER_ROLE.DOC && (
                        <Navbar.Collapse>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/'}
                                onClick={() => navigate('/')}
                            >
                                Home
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/neworder'}
                                onClick={() => navigate('/neworder')}
                            >
                                Auftragsanlage
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/teethView'}
                                onClick={() => navigate('/teethView')}
                            >
                                Restaurationen
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/additionals'}
                                onClick={() => navigate('/additionals')}
                            >
                                Zusatzinfos
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/fileUpload'}
                                onClick={() => navigate('/fileUpload')}
                            >
                                Datei-Upload
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/appointments'}
                                onClick={() => navigate('/appointments')}
                            >
                                Terminplanung
                            </Navbar.Link>
                            <Navbar.Link
                                className="cursor-pointer"
                                active={location.pathname === '/orderViewSingle'}
                                onClick={() => navigate('/orderViewSingle')}
                            >
                                Übersicht
                            </Navbar.Link>
                        </Navbar.Collapse>
                    )}
                </Navbar>
            </nav>
        );
    }
}
