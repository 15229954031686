import axios from './axios.js';

export default async function logout(auth, setAuth, setUserState) {
    try {
        sessionStorage.removeItem('token');
        setAuth({});
        setUserState({
            name: '',
            email: '',
            userRole: 0,
            assignedDocs: [],
        });
        const response = await axios.post(
            '/logout',
            {},
            {
                headers: { Authorization: `Bearer ${auth?.token}` },
            }
        );
        console.log(response.data);
    } catch (error) {
        console.error(error.message);
    }
}
